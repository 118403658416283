import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useAccessToken from '../use-access-token';
import getEnv from '../get-env';
import { alertError } from '../../services/alert';
import { fetchInitialFlagStatus } from '../general-helpers';

type HookReturnType = {
  loading: boolean;
  isCommercialAgent: boolean;
};

type ContractArea = {
  cin?: string;
  zipcode?: string;
};

type Contract = {
  contractTypeId: string;
  title: string;
  active: boolean;
  areas: ContractArea[];
  iFrameConfig: Record<string, unknown>;
  matchCode: string;
};

const CommercialAgentContractTypeId = 7;

export const useIsUserCommercialAgent = (): HookReturnType => {
  const { user } = useAuth0();
  const { accessToken } = useAccessToken();
  const [isCommercialAgent, setIsCommercialAgent] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user || !accessToken) return;

    const fetchFlagStatusAndLoadCustomer = async () => {
      const flagStatus = await fetchInitialFlagStatus(alertError);

      if (flagStatus !== null) {
        getCustomer(user['https://iib-it.de/customer'], flagStatus);
      } else {
        setLoading(false);
      }
    };

    fetchFlagStatusAndLoadCustomer();
  }, [user, accessToken]);

  const getCustomer = React.useCallback(
    async (customerId: number, isEnabled: boolean) => {
      setLoading(true);

      const apiUrl = isEnabled
        ? `${getEnv('REACT_APP_ADMIN_URL')}`
        : `${getEnv('REACT_APP_API_URL')}`;

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Accept', 'application/json');

      if (isEnabled) {
        myHeaders.append('Authorization', `Bearer ${accessToken}`);
      }

      const finalUrl = isEnabled
        ? `${apiUrl}/customers/customers/${customerId}/contracts`
        : `${apiUrl}/customers/customers/${customerId}/contracts?token=${getEnv(
            'REACT_APP_CUSTOMER_API_TOKEN'
          )}`;

      const requestOptions: RequestInit = {
        method: 'GET',
        headers: myHeaders,
      };

      const contractData = await fetch(finalUrl, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .catch((error) =>
          alertError(
            'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
            error
          )
        );

      const customer = await fetch(
        isEnabled
          ? `${apiUrl}/customers/customers/${customerId}`
          : `${apiUrl}/customers/customers/${customerId}?token=${getEnv(
              'REACT_APP_CUSTOMER_API_TOKEN'
            )}`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .catch((error) =>
          alertError(
            'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.',
            error
          )
        );

      const contracts = contractData?.items as Contract[];

      const hasCommercialAgentContract = contracts.some(
        (contract) =>
          Number(contract.contractTypeId) === CommercialAgentContractTypeId
      );

      setIsCommercialAgent(
        customer?.type === 'commercialAgent' || hasCommercialAgentContract
      );
      setLoading(false);
    },
    [accessToken]
  );

  return { isCommercialAgent, loading };
};
