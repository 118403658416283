export const fetchInitialFlagStatus = async (
  alertError: (arg0: string) => void
): Promise<boolean | null> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  const fliptConfig = {
    flagKey: 'customer-api-switch',
    entityId: '4aaa1461-92ae-4681-91bc-60ce5da1f5d1',
    namespaceKey: 'Erfolgscockpit',
  };

  const raw = JSON.stringify({
    flagKey: fliptConfig.flagKey,
    entityId: fliptConfig.entityId,
    context: {},
    namespaceKey: fliptConfig.namespaceKey,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  try {
    const fliptBaseUrl = 'https://flipit.stg.iib-it.de/evaluate/v1/boolean';
    const response = await fetch(fliptBaseUrl, requestOptions);
    const result = await response.json();

    return result.enabled ?? null;
  } catch (error) {
    console.error(error);
    alertError(
      'Es ist ein Problem aufgetreten, versuchen Sie es später erneut.'
    );
    return null;
  }
};
