import {
  CSSProperties,
  FC,
  useEffect,
  useState,
  useContext,
  useRef,
  ReactElement,
} from 'react';
import * as S from './index.style';
import {
  MapMTK,
  MapContext,
  useResizeObserver,
  type MarkerProps,
} from '@iib/pandora-box';
import {
  DEFAULT_MAP_STYLE,
  MAP_ATTRIBUTION,
} from '../../utils/constants/common';
import { TestID } from '../../utils/constants/DataTestId';
import { getEnv } from '../../utils';

type Position = {
  longitude: number;
  latitude: number;
};

export type Pin = {
  position: Position;
  content?: {
    props: {
      children: Array<string | ReactElement>;
    };
  };
};

type MapProps = {
  isTest?: boolean;
  pins?: Pin[];
  style?: CSSProperties;
  className?: string;
};

export const Map: FC<MapProps> = ({
  isTest = false,
  pins = [],
  style,
  className,
}) => {
  const [initialRenderComplete, setInitialRenderComplete] =
    useState<boolean>(isTest); // useEffect does not run twice in jest, so we use true unblock render flow.

  const [currentPin, setCurrentPin] = useState<Pin | null>(null);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const {
    map: mapDictionary,
    waitForMapToLoad,
    addMarker,
    removeMarker,
    markersDictionary,
  } = useContext(MapContext);
  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useResizeObserver(wrapper);

  useEffect(() => {
    if (currentPin) {
      setCenter(currentPin.position);
      setZoom(MAX_ZOOM);
    }
  }, [currentPin]);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  const updateMarkers = async () => {
    const loaded = await waitForMapToLoad?.(
      TestID.ErfolgscockpitLocationPinsCardMap
    );
    if (!loaded || !mapDictionary?.[TestID.ErfolgscockpitLocationPinsCardMap])
      return;

    const mapMTK = mapDictionary[TestID.ErfolgscockpitLocationPinsCardMap];

    Object.entries(markersDictionary || {}).forEach(([key]) => {
      removeMarker?.(key);
    });

    const newMarkersList: MarkerProps[] = pins.map((pin, key) => {
      const children = pin.content?.props.children || [];
      const streetAndNumber = children[0] as string;
      const cityAndPostalCode = children[children.length - 1] as string;

      return {
        location: [pin.position.longitude, pin.position.latitude],
        id: `marker-id-${key}`,
        fitToMarkerBounds: false,
        onClick: () => {
          mapMTK?.gl?.flyTo({
            center: [pin.position.longitude, pin.position.latitude],
            zoom: MAX_ZOOM,
          });
          setCurrentPin(pin);
        },
        options: {
          states: {
            click: {
              'mtk:popup': {
                html: `<div>${streetAndNumber}<br />${cityAndPostalCode}</div>`,
              },
            },
          },
        },
      };
    });

    newMarkersList.forEach((element) => {
      addMarker?.(TestID.ErfolgscockpitLocationPinsCardMap, element);
    });

    if (mapMTK && newMarkersList.length) {
      mapMTK?.gl?.setCenter(newMarkersList[newMarkersList.length - 1].location);
      mapMTK?.gl?.setZoom(DEFAULT_ZOOM);
    }
  };

  useEffect(() => {
    if (!initialRenderComplete) return;
    updateMarkers();
  }, [pins, mapDictionary, initialRenderComplete]);

  useEffect(() => {
    mapDictionary?.[TestID.ErfolgscockpitLocationPinsCardMap]?.gl?.resize();
  }, [dimensions]);

  return (
    <div
      style={{ ...style, height: '100%' }}
      className={className}
      ref={wrapper}
    >
      <S.MapWrapper>
        <MapMTK
          data-testid={TestID.ErfolgscockpitLocationPinsCardMap}
          mapId={TestID.ErfolgscockpitLocationPinsCardMap}
          mapType={DEFAULT_MAP_STYLE}
          apiKey={getEnv('REACT_APP_MTK_API_KEY') || ''}
          styleControl={false}
          zoomControl={false}
          initialViewState={{
            center: [center.longitude, center.latitude],
            zoom,
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            pitch: 0,
            bearing: 0,
          }}
          style={{ height: '100%' }} // Ensure the map fills its container
          attribution={{ customAttribution: MAP_ATTRIBUTION, compact: true }}
        />
      </S.MapWrapper>
    </div>
  );
};

const DEFAULT_ZOOM = 8;
const MIN_ZOOM = 4;
const MAX_ZOOM = 17.9;
const DEFAULT_CENTER: Position = {
  longitude: 10.26517,
  latitude: 51.2481,
};
